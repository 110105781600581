import React from 'react';
import Navbar, {handleClickNav, SectionId} from "../navbar/Navbar";
import './_landing.scss';
import imgBack from '../../assets/images/pic2.jpg';
import imgFront from '../../assets/images/pic1.jpg';

const Landing = () => {
    return (
        <section id={SectionId.LANDING} className="landing-container">
            <Navbar />

            <div className="container">
                <div className="row">

                    <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="landing-left-container">
                                <div className="image-container-one">
                                    <img src={imgBack} alt="" style={{width: '100%', height: '100%'}}/>
                                </div>
                                <div className="image-container-two">
                                    <img src={imgFront} alt="" style={{width: '100%', height: '100%'}}/>
                                </div>
                            </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6" style={{zIndex:1}}>
                        <div className="landing-right-container">
                            <h1>Usaldusväärne ja rahulolu pakkuv lahendus sinu vannitoale.</h1>
                            <p>Kontakteeru juba täna ja küsi pakkumist!</p>
                            <button className="btn btn-outline-light landing-button" onClick={() => handleClickNav('contact')}>võta ühendust</button>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default Landing;
