import React from 'react';
import {SectionId} from "../navbar/Navbar";
import {contactIcons} from "../../assets/Media";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import './_contact.scss';

const Contact = () => {
    return (
        <section id={SectionId.CONTACT} className="contact-container">

            <div className="container">
                
                <div className="row">
                    <h2 className="contact-title">Kontakt</h2>
                </div>
                    
                <div className="row">
                    <div className="col-12 contact-info-container">
                        {contactIcons.map((icon, index) => {
                            return (
                                <div key={`contact-info-${index}`} className={"contact-info"}>
                                    <FontAwesomeIcon className={icon.className} icon={icon.path as IconProp} />
                                    <p>{icon.text}</p>
                                </div>
                            )
                        })}
                    </div>

                </div>
            </div>
        </section>
    );
};

export default Contact;
