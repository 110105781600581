import React from 'react';
import { SectionId } from "../navbar/Navbar";
import logo from "../../assets/icons/logo.svg";
import './_footer.scss';

const Footer = () => {
    return (
        <section id={SectionId.FOOTER} className="footer-container">
            <div className="footer-logo-container">
                <img src={logo} className="footer-logo" alt="Logo"/>
            </div>
            <p>Copyright @ 2022 Vannitoameister OÜ</p>
        </section>
    );
};

export default Footer;
