import React from 'react';
import {SectionId} from "../navbar/Navbar";
import Gallery from "../gallery/Gallery";
import './_projects.scss';

const Projects = () => {
    return (
        <section id={SectionId.PROJECTS} className="projects-container">
            <div className="container">

                <div className="row">
                    <h2 style={{textAlign: "center"}}>Tehtud tööd</h2>
                </div>

                <div className="row">
                    <Gallery />
                </div>

            </div>
        </section>
    );
};

export default Projects;
