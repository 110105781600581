import React from 'react';
import About from "./components/about/About";
import Landing from "./components/landing/Landing";
import Projects from "./components/projects/Projects";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faBusinessTime, faTrowelBricks, faScrewdriverWrench, faPhone, faEnvelope, faLocationDot} from '@fortawesome/free-solid-svg-icons'

library.add(fab, faBusinessTime, faTrowelBricks, faScrewdriverWrench, faPhone, faEnvelope, faLocationDot);

const App = () => {
    return (
        <div className="App">
            <Landing />
            <About  />
            <Projects />
            <Contact />
            <Footer />
        </div>
    );
}

export default App;
