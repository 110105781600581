import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons/faCircle";
import './_pagination.scss';

interface PaginationButtonProps {
    onClick: (index: number) => void;
    index: number;
    isActive?: boolean;
}

const PaginationButton = (props: PaginationButtonProps) => {

    return (
        <FontAwesomeIcon
            className={`pagination-dot ${props.isActive ? 'pagination-dot--active' : ''}`}
            icon={faCircle}
            onClick={() => props.onClick(props.index)}
            key={`pagination-button-${props.index}`}
        />
    );
};

export default PaginationButton;
