import React from 'react';
import { SectionId } from "../navbar/Navbar";
import { aboutIcons } from '../../assets/Media';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import './_about.scss';

const About = () => {
    const aboutText = 'OÜ vannitoameister on 20 aastase kogemusega ettevõte, mis pakub  nii vannitubade ehitust kui ka remonti. ' +
                      'Ettevõte keskendub eelkõige terviklahendustele, kuid teostame ka väiksemaid töid.';

    return (
        <section id={SectionId.ABOUT} className="about-container">
            <div className='container'>

                <div className="row">
                    <div className="col-sm-12 col-md-9 col-lg-6">
                        
                        <div className="about-section__text">
                            <h2>Firmast</h2>
                            <p>{aboutText}</p>
                        </div>

                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-9 col-lg-6">

                        <div className=" about-section__mini-info-banner">
                        {aboutIcons.map((icon, index) => {
                            return (
                                <div key={`mini-info-${index}`} className={"about-section__mini-info-content"}>
                                    <FontAwesomeIcon className={icon.className} icon={icon.path as IconProp} />
                                    <p>{icon.text}</p>
                                </div>
                            )
                        })}
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
    );
};

export default About;
