import React, { useState} from 'react';
import PaginationButton from "../pagination/Pagination";
import './_gallery.scss';

const cache: any = {};

function importAll(r: any) {
    r.keys().forEach((key: any) => (cache[key] = r(key)));
}

importAll(require.context("../../assets/gallery", false, /\.(png|jpe?g|svg)$/));

const images = Object.entries(cache).map(module => module[1]).sort() as string[];

const chunkSize = 5;

const sortImages = (images: string[]) => {
    const orderedImages: {orderNr: number, path: string}[] = [];
    images.forEach((i: string) => {
        const cleanPath = i.split('/');
        orderedImages.push({orderNr: Number(cleanPath[cleanPath.length - 1].split('.')[0]), path: i})
    })

    return orderedImages.sort((a, b) => a.orderNr - b.orderNr);
}

const orderedPath = sortImages(images).map((si) => si.path);

const imagePages = orderedPath.map((e, i) => {
    return i % chunkSize === 0 ? orderedPath.slice(i, i + chunkSize) : null;
}).filter(e => { return e; });

const Gallery = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const pageOfImages = imagePages[currentPage];

    const onPaginationClick = (index: number) => {
        setCurrentPage(index);
    }

    if(!pageOfImages) {
        return null;
    } else {
        return (
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>

                        <div className="project-gallery-container">
                            {pageOfImages.map(image => (
                                <img className="gallery-image" src={String(image)} alt='gallery-image'/>
                            ))}
                        </div>

                        <div className="project-pagination-container">
                            {imagePages.map((v, index) => (
                                <PaginationButton onClick={onPaginationClick} index={index} isActive={index === currentPage}/>
                                )
                            )}
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default Gallery;

