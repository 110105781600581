export const aboutIcons: { path: string, text: string, className: string }[] = [
    {
        path: 'fa-solid fa-business-time',
        text: 'ÜLE 20 AASTA',
        className: 'about-icon'
    },
    {
        path: 'fa-solid fa-trowel-bricks',
        text: 'EHITUS',
        className: 'about-icon'
    },
    {
        path: 'fa-solid fa-screwdriver-wrench',
        text: 'REMONT',
        className: 'about-icon'
    }
]

export const contactIcons: { path: string, text: string, className: string }[] = [
    {
        path: 'fa-solid fa-envelope',
        text: 'vannitoameister@gmail.com',
        className: 'contact-icon'
    },
    {
        path: 'fa-solid fa-phone',
        text: '+372 5303 1111',
        className: 'contact-icon'
    },
    {
        path: 'fa-solid fa-location-dot',
        text: 'Tartumaa, Linaleo, Võsanuka',
        className: 'contact-icon'
    }
]
